<template>
  <v-container class="px-md-10">
    <v-form v-model="user_profile_promotional_code_tab_view_model.form_validity">
      <p
        class="text-h5 font-weight-medium mx-auto text-center primary--text mb-10"
        style="width: 80%;"
      >
        {{ user_profile_promotional_code_tab_view_model.translate('message') }}
      </p>
      <p class="mx-auto text-center primary--text text-text mb-10" style="width: 90%;">
        {{ user_profile_promotional_code_tab_view_model.translate('description') }}
      </p>
      <label class="inactive-blue--text">
        {{ user_profile_promotional_code_tab_view_model.translate('inputs.code.label') }}
      </label>
      <v-text-field
        name="code"
        v-model="user_profile_promotional_code_tab_view_model.inputs.code"
        color="accent"
        class="accent--text pt-0 mb-16"
        :rules="user_profile_promotional_code_tab_view_model.inputs_config.code.rules"
        v-mask="'###-###'"
        :disabled="user_profile_promotional_code_tab_view_model.loading"
      />
      <div class="d-flex justify-center">
        <v-btn
          :loading="user_profile_promotional_code_tab_view_model.loading"
          :disabled="!user_profile_promotional_code_tab_view_model.form_validity"
          color="accent"
          width="80%"
          x-large
          rounded
        >
          {{ user_profile_promotional_code_tab_view_model.translate('submit') }}
        </v-btn>
      </div>
    </v-form>
  </v-container>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator';
import UserProfilePromotionalCodeTabViewModel
  from '@/vue-app/view-models/components/user-profile/user-profile-tabs/user-profile-promotional-code-tab-view-model';

@Component({ name: 'UserProfilePromotionalCodeTab' })
export default class UserProfilePromotionalCodeTab extends Vue {
  user_profile_promotional_code_tab_view_model = Vue.observable(
    new UserProfilePromotionalCodeTabViewModel(),
  );
}
</script>
