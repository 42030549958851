import i18n from '@/vue-app/plugins/i18n';
import { requiredRule } from '@/vue-app/utils/form-rules';

export default class UserProfilePromotionalCodeTabViewModel {
  private readonly i18n_namespace = 'components.user-profile.user-profile-tabs.user-profile-promotional-code-tab';

  inputs = {
    code: '',
  };

  form_validity = false;

  inputs_config = {
    code: {
      rules: [requiredRule],
    },
  };

  loading = false;

  translate = (value: string) => (
    i18n.t(`${this.i18n_namespace}.${value}`) as string
  )
}
